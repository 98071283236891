import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-with-the-api"
    }}>{`Getting Started with the API`}</h1>
    <p>{`To work with Capella’s API, you must first have a valid and active user account. If you do not already have a valid user account, but are interested in purchasing Capella products, please `}<a parentName="p" {...{
        "href": "https://www.capellaspace.com/contact/"
      }}>{`contact us`}</a>{`. If you are interested in getting access to Capella's free and open license sample data, please consider joining our `}<a parentName="p" {...{
        "href": "https://www.capellaspace.com/community/"
      }}>{`developer community`}</a>{`.`}</p>
    <p>{`To get started with the API, we recommend you first read about the basic interactions:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.capellaspace.com/authentication/requesting-api-key"
        }}>{`Requesting an API Key`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.capellaspace.com/accessing-data/searching-for-data"
        }}>{`Searching the Capella Archive`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.capellaspace.com/accessing-data/ordering-and-downloading"
        }}>{`Ordering and Downloading Capella Products`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.capellaspace.com/constellation-tasking/tasking-requests"
        }}>{`Placing a New Tasking Request`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.capellaspace.com/constellation-tasking/tasking-request-status"
        }}>{`Checking the Status of a Tasking Request`}</a></li>
    </ul>
    <p>{`If you would like additional information, Capella has published `}<a parentName="p" {...{
        "href": "/api/access-entitlement"
      }}>{`API reference docs`}</a>{` for details and examples of how to use the various endpoints. `}</p>
    <p>{`For examples of how to work with Capella’s API, please see Capella’s public github: `}<a parentName="p" {...{
        "href": "https://github.com/capellaspace"
      }}>{`github.com/capellaspace`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      